var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{staticClass:"mt-15 mr-15",attrs:{"top":true,"right":true,"left":false,"bottom":false,"direction":"left","open-on-hover":false,"transition":"slide-y-reverse-transition","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-avatar"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-2","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}])},[_c('span',[_vm._v(" Download excel report ")])])]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-avatar"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"green","loading":_vm.isLoadingDailyReport},on:{"click":function($event){$event.stopPropagation();return _vm.exportDaily($event)}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-today")])],1)]}}])},[_c('span',[_vm._v(" Daily report ")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-avatar"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"indigo","loading":_vm.isLoadingMonthlyReport},on:{"click":function($event){$event.stopPropagation();return _vm.exportMonthly($event)}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('span',[_vm._v(" Monthly report ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }