<template>
  <v-main class="pt-3 pl-10 pr-10 pb-7">
    <DialReportExport />
    <v-row class="pa-0 ma-0">
      <v-col lg="12">
        <GeneralStatisticsCard />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col lg="2">
        <StatusPatientToday class="elevation-3"/>
      </v-col>
      <v-col lg="6">
        <DailyRevenueCard class="elevation-3" />
      </v-col>
      <v-col lg="4">
        <ClinicLocations class="elevation-3" />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 mt-7">
      <v-col lg="5">
        <MonthlyRevenue class="elevation-3" />
        <MonthlyRegistration class="elevation-3 mt-7" />
      </v-col>
      <v-col lg="7">
        <TopPayingPatient class="elevation-3" />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import DialReportExport from "@/components/DialReportExport";

export default {
  components: {
    DialReportExport,
    GeneralStatisticsCard: () =>
      import("@/components/reportComponents/GeneralStatistics"),
    DailyRevenueCard: () =>
      import("@/components/reportComponents/DailyRevenue"),
    ClinicLocations: () =>
      import("@/components/reportComponents/ClinicLocations"),
    MonthlyRevenue: () =>
      import("@/components/reportComponents/MonthlyRevenue"),
    MonthlyRegistration: () =>
      import("@/components/reportComponents/MonthlyRegistration"),
    TopPayingPatient: () =>
      import("@/components/reportComponents/TopPayingPatient"),
    StatusPatientToday: () => 
      import("@/components/reportComponents/OverviewToday")
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.col {
  padding: 0px 10px 0px 10px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>