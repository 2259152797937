<template>
  <v-speed-dial
    v-model="fab"
    :top="true"
    :right="true"
    :left="false"
    :bottom="false"
    direction="left"
    :open-on-hover="false"
    transition="slide-y-reverse-transition"
    class="mt-15 mr-15"
    fixed
  >
    <template v-slot:activator>
      <v-tooltip bottom content-class="tooltip-avatar">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-download </v-icon>
          </v-btn>
        </template>
        <span> Download excel report </span>
      </v-tooltip>
    </template>
    <v-tooltip bottom content-class="tooltip-avatar">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          dark
          small
          color="green"
          @click.stop="exportDaily"
          :loading="isLoadingDailyReport"
        >
          <v-icon>mdi-calendar-today</v-icon>
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </template>
      <span> Daily report </span>
    </v-tooltip>
    <v-tooltip bottom content-class="tooltip-avatar">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          dark
          small
          color="indigo"
          @click.stop="exportMonthly"
          :loading="isLoadingMonthlyReport"
        >
          <v-icon>mdi-calendar-month</v-icon>
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </template>
      <span> Monthly report </span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
import ReportService from "@/services/report";
import moment from "moment";

export default {
  data: () => {
    return {
      fab: false,
      isLoadingDailyReport: false,
      isLoadingMonthlyReport: false,
    };
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    getSummary: function (temporal, suffix) {
      return ReportService.summaryReport(
        moment().clone().startOf(temporal).format("YYYY/MM/DD HH:mm"),
        moment().clone().endOf(temporal).format("YYYY/MM/DD HH:mm"),
        moment().clone().format("YYYY-MM-DD-HH-mm-ss") + suffix + ".xlsx"
      );
    },
    exportDaily: async function () {
      this.isLoadingDailyReport = true;
      var result = await this.getSummary("day", "-daily");
      if (!result || result.error) {
        this.showError("Cannot get daily report. Please try again later!");
      }
      this.isLoadingDailyReport = false;
    },
    exportMonthly: async function () {
      this.isLoadingMonthlyReport = true;
      var result = await this.getSummary("month", "-monthly");
      if (!result || result.error) {
        this.showError("Cannot get monthly report. Please try again later!");
      }
      this.isLoadingMonthlyReport = false;
    },
  },
};
</script>

<style scoped>
.col {
  padding: 0px 10px 0px 10px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>